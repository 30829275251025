<!--
 * @Author: 候怀烨
 * @Date: 2021-01-07 16:43:07
 * @LastEditTime: 2021-01-27 14:06:22
 * @LastEditors: Please set LastEditors
 * @Description: 兑奖介质统计
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\rewardStatistics\index.vue
-->
<!-- NAME[epic=动销] 兑奖统计 -->
<template>
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="time">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 250px"
            @change="handlerchange"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="goods_jiezhi">
          <el-select
            v-model="form.goods_jiezhi"
            placeholder="请选择兑奖介质"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Redeem"
              :key="index"
              :label="item.goods_name"
              :value="item.cash_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="user_id">
          <el-select
            v-model="form.user_id"
            placeholder="请选择业务员"
            style="width: 120px"
          >
            <el-option
              v-for="item in Salesman"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="keyword_goods">
          <el-input
            v-model="form.keyword_goods"
            style="width: 200px"
            placeholder="商品名称、助记码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="keyword_cust">
          <el-input
            v-model="form.keyword_cust"
            style="width: 200px"
            placeholder="客户名称、助记码
          "
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.remark"
            placeholder="备注"
            style="width: 120px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handlerchongzhi">清 空</el-button>
        </el-form-item>
        <el-form-item></el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        stripe
        :data="tableData"
        show-summary
        :summary-method="summaryFunction"
      >
        <el-table-column type="index"></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div
              v-if="list.label == '单号'"
              class="underline"
              @click="handleCheckDetail(row)"
            >
              {{ row[list.prop] }}
            </div>
            <div v-else>
              {{ row[list.prop] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <saleOrderDetail ref="saleOrderDetail"></saleOrderDetail>
  </div>
</template>

<script>
  import timepost from '@/utils/timestamp'
  import { postAction } from '@/api/Employee'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'

  export default {
    components: {
      saleOrderDetail,
    },
    data() {
      return {
        total: 0,
        form: {
          start_time: '',
          end_time: '',
          goods_jiezhi: '',
          user_id: '',
          keyword_goods: '',
          keyword_cust: '',
          remark: '', //备注,
          pageSize: 10,
          pageNo: 1,
        },
        time: [],
        Redeem: [],
        Salesman: [],
        tableData: [],
        url: {
          Redeem: '/promoteAdmin/prize/index',
          list: '/promoteAdmin/prize/order-list',
          Salesman: '/baseAdmin/common/staff-option',
        },
        colemd: [
          {
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '200',
          },
          {
            label: '客户名称',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '兑奖介质',
            align: 'center',
            prop: 'jiezhi_name',
            width: '',
          },
          {
            label: '回收数量',
            align: 'center',
            prop: 'jiezhi_num',
            width: '',
          },
          {
            label: '奖品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '数量',
            align: 'center',
            prop: 'quantity',
            width: '',
          },
          {
            label: '售价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            label: '奖品货值',
            align: 'center',
            prop: 'goods_money',
            width: '',
          },
          {
            label: '奖现金',
            align: 'center',
            prop: 'gift_amount',
            width: '',
          },
          {
            label: '日期',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
        ],
      }
    },
    mounted() {
      this.handlerRedeem()
      this.handlerSalesman()
      this.handlerInquire()
    },
    methods: {
      handlerchongzhi() {
        this.$refs['form'].resetFields()
        this.time = []
        this.form.start_time = ''
        this.form.end_time = ''
        this.handlerInquire()
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.handlerlist()
      },
      handlerchange(val) {
        console.log(val)
        if (val.length > 0) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      handlerRedeem() {
        postAction(this.url.Redeem, {})
          .then((res) => {
            console.log(res, 'hhy@123')
            this.Redeem = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerSalesman() {
        postAction(this.url.Salesman, {})
          .then((res) => {
            console.log(res)
            this.Salesman = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.list
            this.total = res.totalCount
            this.tableData.forEach((list) => {
              list.create_at = timepost.timeFormat(list.create_at)
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [4, 8, 9]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                if (idx == 4) {
                  sums[n] = a
                } else {
                  sums[n] = a.toFixed(2) + ' 元'
                }
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handleCheckDetail(row) {
        console.log(row)
        // order_id
        this.$refs['saleOrderDetail'].isshowDialog = true
        this.$refs['saleOrderDetail'].id = row.order_id
      },
    },
  }
</script>

<style></style>
