var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "250px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.handlerchange },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "goods_jiezhi" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择兑奖介质" },
                      model: {
                        value: _vm.form.goods_jiezhi,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "goods_jiezhi", $$v)
                        },
                        expression: "form.goods_jiezhi",
                      },
                    },
                    _vm._l(_vm.Redeem, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.goods_name, value: item.cash_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "user_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择业务员" },
                      model: {
                        value: _vm.form.user_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "user_id", $$v)
                        },
                        expression: "form.user_id",
                      },
                    },
                    _vm._l(_vm.Salesman, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword_goods" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "商品名称、助记码" },
                    model: {
                      value: _vm.form.keyword_goods,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "keyword_goods", $$v)
                      },
                      expression: "form.keyword_goods",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword_cust" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "客户名称、助记码\n        " },
                    model: {
                      value: _vm.form.keyword_cust,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "keyword_cust", $$v)
                      },
                      expression: "form.keyword_cust",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerInquire },
                    },
                    [_vm._v("查 询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.handlerchongzhi } }, [
                    _vm._v("清 空"),
                  ]),
                ],
                1
              ),
              _c("el-form-item"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              attrs: {
                stripe: "",
                data: _vm.tableData,
                "show-summary": "",
                "summary-method": _vm.summaryFunction,
              },
            },
            [
              _c("el-table-column", { attrs: { type: "index" } }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            list.label == "单号"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "underline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCheckDetail(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row[list.prop]) + " ")]
                                )
                              : _c("div", [
                                  _vm._v(" " + _vm._s(row[list.prop]) + " "),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("saleOrderDetail", { ref: "saleOrderDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }